import React, {
	createContext,
	useEffect,
	useState,
	PropsWithChildren,
} from "react";
import { auth } from "../firebase";
import { User } from "firebase";

export interface IAuthContext {
	user: User | null;
	redirect: string | false;
	saveRedirect: (to: string) => void;
}

export const authContext = createContext<IAuthContext>({
	user: null,
	redirect: false,
	saveRedirect: () => {},
});

export const AuthContextProvider = ({ children }: PropsWithChildren<{}>) => {
	const [user, setUser] = useState<User | null>(null);
	const [redirect, setRedirect] = useState<string | false>(false);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((User) => {
			const user = User;
			if (!user) auth.signInAnonymously();
			setUser(user);
		});

		return unsubscribe;
	}, []);

	function saveRedirect(to: string) {
		setRedirect(to);
	}

	return (
		<authContext.Provider value={{ user, redirect, saveRedirect }}>
			{children}
		</authContext.Provider>
	);
};
