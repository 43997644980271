import React from "react";
import LOGO from "../logo.png";
import { Row } from "antd";

export default function Header() {
	return (
		<header
			style={{ display: "flex", justifyContent: "center", padding: 40 }}
		>
			<Row>
				<img
					src={LOGO}
					alt="On The Road Lending"
					style={{ maxHeight: 120 }}
				/>
			</Row>
		</header>
	);
}
