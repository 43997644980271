import React from "react";
import { Layout, Typography } from "antd";
import Header from "./Header";

export default function ThankYou() {
	return (
		<Layout style={{ minHeight: "100vh" }}>
			<Header />
			<Layout.Content
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					padding: 24,
				}}
			>
				<Typography.Title className="otrl-color--green">
					Thank You
				</Typography.Title>
				<Typography.Text strong>
					Someone from On The Road Lending will be reaching out
					shortly.
				</Typography.Text>
			</Layout.Content>
		</Layout>
	);
}
