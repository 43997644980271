import React, { FC } from "react";
import { BrowserRouter as BRouter, Switch, Route } from "react-router-dom";
import Report from "./Report";
import Application from "./Application";
import Auth from "./Auth";
import ThankYou from "./ThankYou";

const Router: FC = () => {
	return (
		<BRouter>
			<Switch>
				<Route path="/login" children={<Auth />} />
				<Route path="/thank-you" children={<ThankYou />} />
				<Route
					path="/application/:salesforceToken"
					children={<Application />}
				/>
				<Route path="/report/:sessionId" children={<Report />} />
			</Switch>
		</BRouter>
	);
};

export default Router;
