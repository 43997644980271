import {
	Button,
	Card,
	Col,
	Layout,
	Menu,
	Result,
	Row,
	Table,
	Descriptions,
	Tag,
} from "antd";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { db } from "../firebase";
import { ColumnProps } from "antd/lib/table";

import {
	Account,
	AssetReportItem,
	HistoricalBalance,
	Transaction,
} from "plaid";

import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	LabelList,
} from "recharts";
import AdminView from "./AdminView";
import Currency from "../lib/currency";
import { CardProps } from "antd/lib/card";
import { authContext } from "../context/AuthContext";
// import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;

const SORTER = (a: any, b: any) => a - b;

const COLORS = [
	"#FF6633",
	"#FFB399",
	"#FF33FF",
	"#00B3E6",
	"#E6B333",
	"#3366E6",
	"#999966",
	"#99FF99",
	"#B34D4D",
	"#80B300",
	"#809900",
	"#E6B3B3",
	"#6680B3",
	"#66991A",
	"#FF99E6",
	"#CCFF1A",
	"#FF1A66",
	"#E6331A",
	"#33FFCC",
	"#66994D",
	"#B366CC",
	"#4D8000",
	"#B33300",
	"#CC80CC",
	"#66664D",
	"#991AFF",
	"#E666FF",
	"#4DB3FF",
	"#1AB399",
	"#E666B3",
	"#33991A",
	"#CC9999",
	"#B3B31A",
	"#00E680",
	"#4D8066",
	"#809980",
	"#E6FF80",
	"#1AFF33",
	"#999933",
	"#FF3380",
	"#CCCC00",
	"#66E64D",
	"#4D80CC",
	"#9900B3",
	"#E64D66",
	"#4DB380",
	"#FF4D4D",
	"#99E6E6",
	"#6666FF",
];

interface IPlaidTransaction extends Transaction {
	account_name: string;
}

const REPORT_COLUMNS: Array<ColumnProps<IPlaidTransaction>> = [
	{
		dataIndex: "name",
		title: "Name",
		onFilter: (value, record) => record.name?.indexOf(value) === 0,
		sorter: (a, b) => SORTER(a.name?.length, b.name?.length),
		width: "22%",
	},
	{
		dataIndex: "category",
		render: (record) => <>{record[0]}</>,
		title: "Category",
		onFilter: (value, record) => record.category?.indexOf(value) === 0,
		sorter: (a, b) =>
			SORTER(
				a.category?.toString().trim().length,
				b.category?.toString().trim().length,
			),
		width: "22%",
	},
	{
		dataIndex: "amount",
		render: (v) => Currency(v as number),
		title: "Amount",
		onFilter: (value, record) => record.amount === 0,
		sorter: (a, b) => SORTER(a.amount, b.amount),
		width: "22%",
	},
	{
		dataIndex: "date",
		title: "Date",
		sorter: (a, b) => {
			let dateA = new Date(a.date),
				dateB = new Date(b.date);
			return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
		},
		width: "22%",
	},
	{
		dataIndex: "pending",
		render: (record) => (record ? "Pending" : "-"),
		title: "Status",
		onFilter: (value, record) => (record.pending ? true : false),
		sorter: (a, b) => SORTER(a.name?.length, b.name?.length),
	},
];

const Report: FC = () => {
	const { sessionId } = useParams();
	const { user, saveRedirect } = useContext(authContext);
	const [shouldRedirect, setShouldRedirect] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [historicalBalances, setHistoricalBalances] = useState<
		AssetReportItem
	>();
	const [displayTransactions, setDisplayTransactions] = useState<
		IPlaidTransaction[]
	>([]);
	const [depositTransactions, setDepositTransactions] = useState<
		IPlaidTransaction[]
	>([]);
	const [accountAdresses, setAccountAdresses] = useState<IPlaidTransaction[]>(
		[],
	);
	const [loading, setLoading] = useState(false);
	const [documentsInDB, setDocumentsInDB] = useState<any[]>([]);
	const [selectedDocument, setSelectedDocument] = useState<number>(0);

	const bindDocument = (val: any) => {
		setSelectedDocument(val);
		const data = val.data();
		console.log(data);
		const transactions =
			data &&
			data.transactions &&
			data.accounts &&
			data.transactions.map((it: any) => {
				const account = [...data.accounts].filter(
					(acc: any) => acc.account_id === it.account_id,
				)[0];
				return {
					account_name: account.name,
					...it,
				};
			});

		setHistoricalBalances(data && data.report);
		setSelectedAccount(data && data.accounts[0].account_id);
		setAccounts(data && data.accounts);
		setDisplayTransactions(
			transactions.filter((it: any) => it.amount >= 0),
		);
		setDepositTransactions(transactions.filter((it: any) => it.amount < 0));
		setAccountAdresses(data && data.report.accounts);
	};

	async function* init() {
		setLoading(true);
		try {
			const response = await db
				.collection("session")
				.where("sessionId", "==", sessionId)
				.orderBy("createdOn", "desc")
				.get();
			const { docs } = response;
			const [latestDocument] = docs;
			yield;
			if (latestDocument) {
				bindDocument(latestDocument);
				setDocumentsInDB(docs);
				setLoading(false);
			} else {
				throw new Error("Report is not exist");
			}
		} catch (e) {
			console.error(e);
			setShouldRedirect(true);
		}
	}

	useEffect(() => {
		console.log("useEffect");
		const start = init();
		(async () => {
			await start.next();
			start.next();
		})();

		return () => {
			start.return();
		};
		// This effect only needs to be run once
		// eslint-disable-next-line
	}, []);

	const selectedAccountTransactions =
		selectedAccount === "all"
			? displayTransactions
			: displayTransactions.filter(
					(t) => t.account_id === selectedAccount,
			  );

	const selectedDepositTransactions =
		selectedAccount === "all"
			? depositTransactions
			: depositTransactions.filter(
					(t) => t.account_id === selectedAccount,
			  );

	const selectedAddresses =
		selectedAccount === "all"
			? accountAdresses
			: accountAdresses.filter((a) => a.account_id === selectedAccount);

	const filteredAddress = selectedAddresses.map((it: any) => it.owners[0])[0];
	console.log("selectedAddresses", filteredAddress);

	const historicalCategorySpend = [...selectedAccountTransactions].reduce(
		(a: any, b) => {
			const [year, month] = b.date.split("-");
			const key = `${month}-${year}`;

			if (a[key]) {
				a[key].push(b);
			} else {
				a[key] = [b];
			}
			return a;
		},
		{},
	);

	const historicalCategoryDeposits = [...selectedDepositTransactions].reduce(
		(a: any, b) => {
			const [year, month] = b.date.split("-");
			const key = `${month}-${year}`;

			if (a[key]) a[key].push(b);
			else a[key] = [b];
			return a;
		},
		{},
	);

	const grandTotal = () => {
		const totalExpenses = selectedAccountTransactions
			.map((a, b) => a.amount)
			.reduce((acc: any, cur: any) => acc + cur, 0);
		const totalDeposits = selectedDepositTransactions
			.map((a, b) => a.amount)
			.reduce((acc: any, cur: any) => acc + cur, 0);

		return {
			expenses: totalExpenses,
			deposits: totalDeposits,
		};
	};

	const categories = Object.keys(historicalCategorySpend).map((key) => {
		const transactionsForDate = [...selectedAccountTransactions].filter(
			(t) => {
				const [year, month] = t.date.split("-");

				return `${month}-${year}` === key;
			},
		);
		const categoriesForDate = [...transactionsForDate].reduce(
			(a: any, b) => {
				const k = b.category && b.category[0];
				if (k) {
					a[k] = [];
				}
				return a;
			},
			{},
		);
		const categorySpend = Object.keys(categoriesForDate).reduce(
			(a: any, b) => {
				a[b] = [...transactionsForDate]
					.filter((t) => {
						const c = t.category && t.category[0];
						return b === c;
					})
					.reduce((a: number, b) => (b.amount ? a + b.amount : a), 0);
				return a;
			},
			{},
		);

		return {
			name: key,
			...categorySpend,
		};
	});
	const categoryKeysDict = [...categories].reduce((a: any, b) => {
		const { name, ...rest } = b;
		Object.keys(rest).map((k) => (a[k] = []));
		return a;
	}, {});
	const categoryKeys = Object.keys(categoryKeysDict);

	const deposits = Object.keys(historicalCategoryDeposits).map((key) => {
		const transactionsForDate = [...selectedDepositTransactions].filter(
			(t) => {
				const [year, month] = t.date.split("-");

				return `${month}-${year}` === key;
			},
		);
		const categoriesForDate = [...transactionsForDate].reduce(
			(a: any, b) => {
				const k = b.category && b.category[0];
				if (k) a[k] = [];
				return a;
			},
			{},
		);
		const categoryDeposit = Object.keys(categoriesForDate).reduce(
			(a: any, b) => {
				a[b] = [...transactionsForDate]
					.filter((t) => {
						const c = t.category && t.category[0];
						return b === c;
					})
					.reduce((a: number, b) => (b.amount ? a + b.amount : a), 0);
				return a;
			},
			{},
		);

		return {
			name: key,
			...categoryDeposit,
		};
	});
	const depositsKeysDict = [...deposits].reduce((a: any, b) => {
		const { name, ...rest } = b;
		Object.keys(rest).map((k) => (a[k] = []));
		return a;
	}, {});
	const depositsKeys = Object.keys(depositsKeysDict);

	const mapHistoricalBalances = (it: HistoricalBalance) => ({
		amount: it.current,
		balance: Number(it.current).toLocaleString("en-US", {
			currency: "USD",
			style: "currency",
		}),
		date: it.date,
	});
	const hasSelectedAccount =
		selectedAccount && historicalBalances && selectedAccount !== "all";
	const historical =
		hasSelectedAccount &&
		historicalBalances?.accounts
			.filter((it) => it.account_id === selectedAccount)[0]
			.historical_balances.map(mapHistoricalBalances);
	const selectedAccountTitle =
		hasSelectedAccount &&
		historicalBalances?.accounts.filter(
			(acc) => acc.account_id === selectedAccount,
		)[0].name;
	const cardProps: CardProps = {
		bodyStyle: loading ? { padding: "16px 24px" } : { padding: "1px 0" },
		loading,
		style: { borderRadius: 8 },
	};

	if (shouldRedirect && !user?.isAnonymous) {
		return (
			<AdminView>
				<Layout.Content style={{ padding: 48, minHeight: "100vh" }}>
					<Result
						status="warning"
						title="Missing Report"
						subTitle={`Report #${sessionId} was not found`}
					/>
				</Layout.Content>
			</AdminView>
		);
	}

	if (
		shouldRedirect &&
		(user?.isAnonymous ||
			[
				/.*@simplhq.com/,
				/.*@ontheroadlending.org/,
				/.*@scopeinc.com/,
				/titanofdarkness@gmail.com/,
			].some((regExp) => user?.email?.match(regExp)))
	) {
		saveRedirect(String(`report/${sessionId}`));
		return <Redirect to="/login" />;
	}

	const RECORDS_COLUMNS: Array<ColumnProps<any>> = [
		{
			dataIndex: "id",
			title: "Database ID",
		},
		{
			key: "timestamp",
			render: (text, record) =>
				moment
					.unix(record.data().createdOn.seconds)
					.format("dddd, MMMM Do YYYY, h:mm:ss a"),
			title: "Timestamp",
		},
		{
			key: "action",
			render: (text, record) => (
				<span>
					<Button
						type="danger"
						onClick={async (event) => {
							const { id } = record;
							event.stopPropagation();
							console.log(id);
							try {
								const el = documentsInDB.find(
									(doc) => doc.id === id,
								);
								const docs = documentsInDB.filter(
									(doc) => doc.id !== id,
								);
								const [latest] = docs;
								if (latest) {
									bindDocument(latest);
								} else {
									setShouldRedirect(true);
								}
								setDocumentsInDB(docs);
								await el.ref.delete();
							} catch (e) {
								console.error("Record wasn't deleted");
								console.error(e);
							}
						}}
					>
						Delete
					</Button>
				</span>
			),
			title: "Action",
		},
	];

	const renderTotalExpenses = (props: any) => {
		const { x, y, width, index } = props;
		const current = categories[index];
		const radius = 13;
		let total = 0;

		for (const key in current) {
			let value = current[key];
			if (!isNaN(value)) total = total + value;
		}

		return (
			<g>
				<text
					x={x + width / 2}
					y={20} // y - radius
					fill="#222"
					textAnchor="middle"
					dominantBaseline="middle"
				>
					{Currency(total)}
				</text>
			</g>
		);
	};

	const renderTotalDeposits = (props: any) => {
		const { x, y, width, height, index } = props;
		const current = deposits[index];
		const radius = -13;
		let total = 0;

		for (const key in current) {
			let value = current[key];
			if (!isNaN(value)) total = total - value;
		}

		return (
			<g>
				<text
					x={x + width / 2}
					y={20} // y + radius + height
					fill="#222"
					textAnchor="middle"
					dominantBaseline="middle"
				>
					{Currency(total)}
				</text>
			</g>
		);
	};

	return (
		<AdminView>
			<Layout.Content style={{ padding: 48, minHeight: "100vh" }}>
				<Row style={{ marginBottom: 48 }}>
					<Card title="Records" {...cardProps}>
						<Table
							pagination={{
								pageSize: 10,
								style: { paddingRight: 24 },
							}}
							dataSource={documentsInDB}
							columns={RECORDS_COLUMNS}
							rowKey="id"
							size="small"
							onRow={(record, rowIndex) => {
								return {
									onClick: (/* event */) => {
										const { id } = record;
										const el = documentsInDB.find(
											(doc) => doc.id === id,
										);
										bindDocument(el);
									}, // click row
								};
							}}
							rowClassName={(record, index) => {
								if (record === selectedDocument) {
									return "rowHighlight";
								} else {
									return "";
								}
							}}
						/>
					</Card>
				</Row>
				<Row style={{ marginBottom: 48 }}>
					<Card {...cardProps} title="Accounts">
						<Menu
							mode="horizontal"
							selectedKeys={[
								(selectedAccount && selectedAccount) || "",
							]}
							onClick={(e) => setSelectedAccount(e.key)}
						>
							{[
								{ account_id: "all", name: "All" },
								...accounts,
							].map((acc) => (
								<Menu.Item key={acc.account_id}>
									{acc.name}
								</Menu.Item>
							))}
						</Menu>
						<Table
							pagination={{
								pageSize: 25,
								style: { paddingRight: 24 },
							}}
							size="small"
							dataSource={selectedAccountTransactions}
							columns={REPORT_COLUMNS}
							rowKey="transaction_id"
						/>
					</Card>
				</Row>

				<Row style={{ marginBottom: 48 }}>
					<Card title="User Info" bodyStyle={{ padding: 0 }}>
						<Col>
							<Descriptions bordered size="small">
								<Descriptions.Item label="Name" span={4}>
									{filteredAddress?.names[0]}
								</Descriptions.Item>

								<Descriptions.Item
									label="Phone numbers"
									span={4}
								>
									{filteredAddress?.phone_numbers.map(
										(it: any) => {
											return (
												<>
													<span
														style={{
															textTransform:
																"capitalize",
														}}
													>
														{it.type}:
													</span>
													<a href={`tel:${it.data}`}>
														{it.data}
													</a>
													{it.primary ? (
														<Tag
															color="cyan"
															style={{
																marginLeft: 8,
															}}
														>
															{" "}
															Primary{" "}
														</Tag>
													) : (
														""
													)}
													<br />
												</>
											);
										},
									)}
								</Descriptions.Item>

								<Descriptions.Item label="Emails" span={4}>
									{filteredAddress?.emails.map((it: any) => {
										return (
											<>
												<a href={`mailto:${it.data}`}>
													{it.data}
												</a>
												{it.primary ? (
													<Tag
														color="cyan"
														style={{
															marginLeft: 8,
														}}
													>
														Primary
													</Tag>
												) : (
													""
												)}
												<br />
											</>
										);
									})}
								</Descriptions.Item>

								<Descriptions.Item label="Address" span={4}>
									{filteredAddress?.addresses.map(
										(it: any) => {
											return (
												<>
													{it.data.street},{" "}
													{it.data.region}{" "}
													{it.data.postal_code},{" "}
													{it.data.city},{" "}
													{it.data.country}
													{it.primary ? (
														<Tag
															color="cyan"
															style={{
																marginLeft: 8,
															}}
														>
															Primary
														</Tag>
													) : (
														""
													)}
													<br />
												</>
											);
										},
									)}
								</Descriptions.Item>
							</Descriptions>
						</Col>
					</Card>
				</Row>

				<Row gutter={48} style={{ marginBottom: 48 }}>
					<Col xs={12}>
						<Card title="Large Expenses" {...cardProps}>
							<Table
								rowKey="transaction_id"
								pagination={{
									pageSize: 5,
									style: { paddingRight: 24 },
								}}
								dataSource={selectedAccountTransactions
									.filter((t) => t.amount && t.amount >= 250)
									.map((t, idx) => ({ idx, ...t }))}
								columns={[
									{
										dataIndex: "idx",
										render: (r) => r + 1,
										title: "#",
										width: "10%",
									},
									{
										dataIndex: "name",
										title: "Name",
										onFilter: (value, record) =>
											record.name?.indexOf(value) === 0,
										sorter: (a, b) =>
											SORTER(
												a.name?.length,
												b.name?.length,
											),
										width: "60%",
									},
									{
										dataIndex: "amount",
										render: (v) => Currency(v as number),
										title: "Amount",
										onFilter: (value, record) =>
											record.amount === 0,
										sorter: (a, b) =>
											SORTER(a.amount, b.amount),
										width: "30%",
									},
								]}
							/>
						</Card>
					</Col>
					<Col xs={12}>
						<Card
							title="Category Breakdown"
							extra={
								<>
									Total:{" "}
									<b>{Currency(grandTotal().expenses)}</b>
								</>
							}
							{...cardProps}
						>
							<ResponsiveContainer height={300} width="100%">
								<BarChart
									data={categories}
									margin={{
										bottom: 24,
										left: 24,
										right: 24,
										top: 35,
									}}
								>
									<XAxis dataKey="name" />
									<YAxis
										tickFormatter={(val) =>
											Currency(val as number)
										}
									/>
									<Tooltip
										formatter={(val, name) => [
											Currency(val as number),
											name,
										]}
									/>
									<Legend />
									{categoryKeys.map((it, i) => (
										<Bar
											dataKey={it}
											key={it}
											stackId="a"
											fill={COLORS[i % COLORS.length]}
										>
											{i === categoryKeys.length - 1 ? (
												<LabelList
													position="top"
													dataKey="name"
													content={
														renderTotalExpenses
													}
												/>
											) : null}
										</Bar>
									))}
								</BarChart>
							</ResponsiveContainer>
						</Card>
					</Col>
				</Row>
				<Row gutter={{ xs: 0, md: 48 }} style={{ marginBottom: 48 }}>
					<Col xs={24} md={12}>
						<Card title="Deposits" {...cardProps}>
							<Table
								rowKey="transaction_id"
								pagination={{
									pageSize: 5,
									style: { paddingRight: 24 },
								}}
								dataSource={selectedDepositTransactions.map(
									(t, idx) => ({ idx, ...t }),
								)}
								columns={[
									{
										dataIndex: "idx",
										title: "#",
										render: (r) => r + 1,
										width: "10%",
									},
									{
										dataIndex: "name",
										title: "Name",
										onFilter: (value, record) =>
											record.name?.indexOf(value) === 0,
										sorter: (a, b) =>
											SORTER(
												a.name?.length,
												b.name?.length,
											),
										width: "60%",
									},
									{
										dataIndex: "amount",
										title: "Amount",
										render: (v) => Currency(v as number),
										onFilter: (value, record) =>
											record.amount === 0,
										sorter: (a, b) =>
											SORTER(a.amount, b.amount),
										width: "30%",
									},
								]}
							/>
						</Card>
					</Col>
					<Col xs={24} md={12}>
						<Card
							title="Deposit Breakdown"
							extra={
								<>
									Total:
									<b>{Currency(grandTotal().deposits)}</b>
								</>
							}
							{...cardProps}
						>
							<ResponsiveContainer height={350} width="100%">
								<BarChart
									data={deposits}
									margin={{
										left: 24,
										right: 24,
										top: 35,
										bottom: 24,
									}}
								>
									<XAxis dataKey="name" />
									<YAxis
										tickFormatter={(val) =>
											Currency(val as number)
										}
									/>
									<Tooltip
										formatter={(val, name) => [
											Currency(val as number),
											name,
										]}
									/>
									<Legend />
									{depositsKeys.map((it, i) => (
										<Bar
											dataKey={it}
											key={it}
											stackId="monthStack"
											fill={COLORS[i % COLORS.length]}
										>
											{i === 0 ? (
												<LabelList
													position="bottom"
													dataKey="name"
													content={
														renderTotalDeposits
													}
												/>
											) : null}
										</Bar>
									))}
								</BarChart>
							</ResponsiveContainer>
						</Card>
					</Col>
				</Row>
				{historical && (
					<Row>
						<Card
							{...cardProps}
							bodyStyle={{ padding: 32 }}
							title={`${selectedAccountTitle} Balance`}
						>
							<ResponsiveContainer height={350}>
								<LineChart
									data={historical.reverse()}
									margin={{ left: 24, right: 24 }}
								>
									<CartesianGrid strokeDasharray="4 8" />
									<XAxis
										dataKey="date"
										tickFormatter={(value) =>
											moment(value).format("l")
										}
									/>
									<YAxis
										dataKey="amount"
										tickFormatter={(value) =>
											Currency(value)
										}
									/>
									<Tooltip
										formatter={(value, name) => {
											switch (name) {
												case "date":
													return moment(value).format(
														"l",
													);
												case "amount":
													return Currency(
														value as number,
													);
												default:
													return value;
											}
										}}
									/>
									<Line
										type="monotone"
										dataKey="amount"
										stroke="#8884d8"
									/>
								</LineChart>
							</ResponsiveContainer>
						</Card>
					</Row>
				)}
			</Layout.Content>
		</AdminView>
	);
};

export default Report;
