import { Card, Col, Icon, Layout, Modal, Row, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import PlaidLink, { PlaidLinkProps } from "react-plaid-link";
import { Redirect, useParams } from "react-router-dom";
import { db, functions } from "../firebase";
import Header from "./Header";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { AssetReportAccount, Identity } from "plaid";

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

export default function Application() {
	const { salesforceToken } = useParams();
	const [showLoading, setShowLoading] = useState(false);
	const [successRedirect, setSuccessRedirect] = useState(false);
	const [plaidConnects, setPlaidConnects] = useState<any[]>([]);

	const handleOnSuccess: PlaidLinkProps["onSuccess"] = async (
		publicToken,
	) => {
		setShowLoading(true);
		try {
			const req = await functions.httpsCallable("bindSalesforceToPlaid")({
				publicToken,
				salesforceToken,
			});
			if (req.data.success) setSuccessRedirect(true);
		} catch (e) {
			Modal.error({
				content: "We could not complete your application.",
				title: "Please Try Again",
			});
			console.error(e);
		} finally {
			setShowLoading(false);
		}
	};
	const processReport = (dataArray: any[]): any[] => {
		const result: any[] = [];
		let index = 1;
		dataArray.forEach((doc) => {
			const { id } = doc;
			const data: any = doc.data();
			const timestamp = moment
				.unix(data.createdOn.seconds)
				.format("dddd, MMMM Do YYYY, h:mm:ss a");
			const { accounts } = data.report;
			accounts.forEach((account: AssetReportAccount) => {
				const { name: bankName, owners } = account;
				const personName = owners
					.map((ident) => {
						return ident.names.join("; ");
					})
					.join(", ");
				result.push({
					bankName,
					id,
					index,
					personName,
					timestamp,
				});
				index += 1;
			});
		});
		return result;
	};
	useEffect(() => {
		console.log("useEffect");
		(async () => {
			const response = await db
				.collection("session")
				.where("sessionId", "==", salesforceToken)
				.orderBy("createdOn", "desc")
				.get();
			const { docs } = response;
			const processedData = processReport(docs);
			setPlaidConnects(processedData);
		})();
	}, [salesforceToken]);

	const PLAID_CONNECTS_COLUMNS: Array<ColumnProps<any>> = [
		{
			dataIndex: "index",
			key: "index",
			title: "#",
		},
		{
			dataIndex: "timestamp",
			key: "timestamp",
			title: "Timestamp",
		},
		{
			dataIndex: "bankName",
			key: "bankName",
			title: "Bank Name",
		},
		{
			dataIndex: "personName",
			key: "personName",
			title: "Person Name",
		},
	];
	return successRedirect ? (
		<Redirect to="/thank-you" />
	) : (
		<Layout style={{ minHeight: "100vh" }}>
			<Modal
				visible={showLoading}
				closable={false}
				footer={null}
				centered
			>
				<Title
					style={{ margin: 0, display: "flex", alignItems: "center" }}
					type="secondary"
					level={3}
				>
					<Icon
						type="loading"
						style={{
							color: "#008ad8",
							fontSize: 40,
							marginRight: 24,
						}}
					/>
					<Text>Saving your Information</Text>
				</Title>
			</Modal>
			<Header />
			<Content style={{ display: "flex", flexDirection: "column" }}>
				<Row type="flex" justify="center">
					<Col xs={20} md={16} lg={10} xl={8}>
						<Typography style={{ textAlign: "center" }}>
							<Title
								className="futura otrl-color--blue"
								style={{ textTransform: "uppercase" }}
							>
								Complete Your Application
							</Title>
							<Paragraph style={{ fontSize: 20 }}>
								You're almost there! We just need your financial
								information to process your application. Please
								Quick Share your information with us. If you are
								unable to, please use the other link in your
								welcome email to upload your two most recent
								bank statements.
							</Paragraph>
						</Typography>
					</Col>
				</Row>
				<Row
					style={{ padding: 24 }}
					type="flex"
					justify="center"
					align="stretch"
				>
					<Col sm={24} lg={8} xl={7}>
						<Card
							title="Quick Share"
							style={{ minHeight: "100%" }}
							headStyle={{ fontSize: 24, textAlign: "center" }}
						>
							<Typography
								style={{
									fontSize: 16,
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Paragraph>
									Connect and share your bank account with
									OnTheRoadLending
								</Paragraph>
								<Paragraph type="secondary">
									We safely and securely access your
									information with Plaid.
								</Paragraph>
								<span
									style={{
										textAlign: "center",
										marginTop: 16,
									}}
								>
									<PlaidLink
										style={{}}
										className="ant-btn ant-btn-primary ant-btn-lg"
										clientName="On The Road Lending"
										env={
											process.env
												.REACT_APP_plaidEnv as "sandbox"
										}
										publicKey={
											process.env
												.REACT_APP_plaidPublicKey as string
										}
										product={
											process.env.REACT_APP_plaidProducts?.split(
												",",
											) as "auth"[]
										}
										apiVersion="v2"
										onSuccess={handleOnSuccess}
									>
										Link your Account
									</PlaidLink>
								</span>
							</Typography>
						</Card>
					</Col>
				</Row>
				<Row justify="center" type="flex" style={{ marginTop: "auto" }}>
					<Col lg={8}>
						<Table
							dataSource={plaidConnects}
							rowKey="index"
							size="small"
							columns={PLAID_CONNECTS_COLUMNS}
							pagination={{
								pageSize: 5,
							}}
						/>
					</Col>
				</Row>
				<Row justify="center" type="flex" style={{ marginTop: "auto" }}>
					<Col lg={8} style={{ padding: 24, marginTop: 24 }}>
						<Text type="secondary">
							We are able to provide our clients with low-cost car
							loans through the generous support of foundations
							and donors who are interested in helping working
							families achieve financial stability. As a condition
							of their grants, we are required to report on
							long-term outcomes. By continuing with this process,
							you are allowing us the ability to view your
							financial picture through the life of your loan so
							that we can report on economic mobility. Thank you
							for your consent.
						</Text>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
}
