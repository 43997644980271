export default function Currency(
	value: number,
	config = { locale: "en-US", currency: "USD" },
): string {
	const { locale, currency } = config;
	return Number(value).toLocaleString(locale, {
		style: "currency",
		currency,
	});
}
