import React, { PropsWithChildren, useState } from "react";
import { Layout, PageHeader, Button } from "antd";
import LOGO from "../logo.png";
import { auth } from "../firebase";

export default function AdminView({ children }: PropsWithChildren<{}>) {
	const [isLoggingOut, setIsLoggingOut] = useState(false);

	async function logout() {
		setIsLoggingOut(true);
		await auth.signOut();
	}

	return (
		<Layout>
			<PageHeader
				style={{
					background: "#fff",
					borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
				}}
				title={
					<span
						style={{
							display: "flex",
							alignItems: "center",
						}}
					>
						<img
							style={{ maxHeight: 28, marginRight: 16 }}
							src={LOGO}
							alt="on the road lending"
						/>
						Applicant Verification
					</span>
				}
				extra={
					<Button loading={isLoggingOut} onClick={logout}>
						Sign Out
					</Button>
				}
			/>
			{children}
		</Layout>
	);
}
