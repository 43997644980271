import React, { useContext } from "react";
import { auth, authProvider } from "../firebase";
import { authContext } from "../context/AuthContext";
import { Redirect } from "react-router-dom";
import { Layout, Button, Typography, Tooltip } from "antd";
import LOGO from "../logo.png";

const { Title } = Typography;
const { Content } = Layout;

export default function Auth() {
	const { user, redirect } = useContext(authContext);

	async function login() {
		await auth.signInWithPopup(authProvider);
	}

	return [
		/.*@simplhq.com/,
		/.*@ontheroadlending.org/,
		/.*@scopeinc.com/,
	].some((regExp) => user?.email?.match(regExp)) && redirect ? (
		<Redirect to={redirect} />
	) : (
		<Layout>
			<Content
				style={{
					minHeight: "100vh",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						padding: 24,
					}}
				>
					<img
						style={{ maxHeight: 48, marginRight: 16 }}
						src={LOGO}
						alt="On The Road Lending"
					/>
					<Title level={2} style={{ margin: 0 }}>
						Application Management
					</Title>
				</div>

				<Tooltip
					placement="bottom"
					title="Please select your On The Road Lending account"
				>
					<Button onClick={login} size="large" icon="google">
						Log In
					</Button>
				</Tooltip>
			</Content>
		</Layout>
	);
}
