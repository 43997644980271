import React from "react";
import Router from "./components/Router";
import { AuthContextProvider } from "./context/AuthContext";
import "./App.css";

const App: React.FC = () => (
    <AuthContextProvider>
        <Router />
    </AuthContextProvider>
);

export default App;
